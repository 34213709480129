let mobileMenuJS;
mobileMenuJS         = function () {

    const body = $('body');
    const btn  = $('.js-mobile-menu-btn');
    const menu = $('.js-mobile-menu');

    const init = function () {
        btn.on('click', function () {
            if ($(this).hasClass('is-active')) {
                hide();
            } else {
                show();
            }
        });
    };

    const show = function () {
        body.addClass('has-mobile-menu');
        btn.addClass('is-active');
        menu.addClass('is-active');
        setTimeout(function () {
            menu.addClass('is-active');
        });
    };

    const hide = function () {
        body.removeClass('has-mobile-menu');
        btn.removeClass('is-active');
        menu.removeClass('is-show');
        setTimeout(function () {
            menu.removeClass('is-active');
        }, 300);
    };

    this.run = function () {
        init();
    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __mobileMenuJS = new mobileMenuJS();
