let googleMapJS;
googleMapJS         = function () {

    this.run = function (div, pins) {

        const el = $('#' + div + '');

        let map;
        let infowindow = new google.maps.InfoWindow();

        const mapOptions = {
            scrollwheel       : false,
            disableDefaultUI  : true,
            zoom              : parseInt(el.attr('data-zoom')),
            zoomControl       : true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL
            },
            center            : new google.maps.LatLng(pins[0]['lat'], pins[0]['lng']),
            styles            : [
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e9e9e9"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dedede"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                }
            ]
        };

        if (el.length > 0) {
            map = new google.maps.Map(document.getElementById(div), mapOptions);
        }

        $.each(pins, function (key, value) {
            const marker = new google.maps.Marker({
                position : new google.maps.LatLng(value['lat'], value['lng']),
                map      : map,
                icon: '../wbn-assets/images/pin.png',
                animation: google.maps.Animation.DROP
            });

            if (value['iw']) {
                google.maps.event.addListener(marker, 'click', function(){
                    infowindow.close();
                    infowindow.setContent('<div class="map-iw-head">'+ value['iwHead'] +'</div><div class="map-iw-body">'+ value['iwBody'] +'</div>');
                    infowindow.open(map, marker);
                });

                if (value['iwVisible']) {
                    infowindow.setContent('<div class="map-iw-head">'+ value['iwHead'] +'</div><div class="map-iw-body">'+ value['iwBody'] +'</div>');
                    infowindow.open(map, marker);
                }
            }
        });

    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __googleMapJS = new googleMapJS();
