let SiteJS;
let window_width = $(window).width();
let window_sm = 576;
let window_md = 768;
let window_lg = 992;
let window_xl = 1200;

SiteJS = function () {

    const plugins = function () {
        $('.center-image').centerImage();
        $('.fancybox').fancybox();
        $('.fancybox-gallery').fancybox({
            helpers: {
                thumbs: {
                    width: 70,
                    height: 70
                },
                title: {type: 'inside'}
            }
        });
        $('.fancybox-video').fancybox({
            maxWidth: 735,
            maxHeight: 600,
            fitToView: false,
            width: '70%',
            height: '70%',
            autoSize: false,
            closeClick: false
        })
        $('input[type="checkbox"] , input[type="radio"]').checkRadio();
        $(':input').inputmask();
        $('[data-toggle="tooltip"]').tooltip({
            container: 'body'
        });
        $('form').validationEngine();
    };

    const javalar = function () {
        $('header.site-head .menu nav ul li ul li.active').parents('li').addClass('active');
        $('table').addClass('table table-bordered table-striped table-hover');
        $('header.site-head .menu > nav > ul > li.active').find('> a').addClass('border-color-1');
        $('header.site-head .menu > nav > ul > li').hover(function () {
            $(this).find('a').toggleClass('border-color-1');
        });
        $('header.site-head .menu > nav > ul > li > ul > li').addClass('bg-color-1');
        $('header.site-head .menu > nav > ul > li.active').hover(function () {
            $(this).find('a').addClass('border-color-1');
        });
        $('header.site-head .menu > nav > ul > li > ul').prepend('<span class="border-bottom-color-1"></span>');
        $('footer.site-foot .links ul li').prepend('<span class="border-color-1"></span>');
    };

    const slider = function () {
        var slider = $('.slider-box .rev_slider');
        if (slider.length > 0) {
            var height = slider.attr('data-height').split(',').map(function (item) {
                return parseInt(item, 10);
            });

            slider.revolution({
                delay: 5000,
                visibilityLevels: [2048, 1024, 778, 480],	// Single or Array for Responsive Visibility Levels i.e.: 4064 or i.e. [2048, 1024, 768, 480]
                responsiveLevels: [2048, 1183, 1007, 480],					// Single or Array for Responsive Levels i.e.: 4064 or i.e. [2048, 1024, 768, 480]
                gridwidth: [1160, 720, 720, 480],							// Single or Array i.e. 960 or [960, 840,760,460]
                gridheight: height,							// Single or Array i.e. 500 or [500, 450,400,350]
                minHeight: 0,
                autoHeight: "off",
                sliderType: "standard",				// standard, carousel, hero
                sliderLayout: "fullscreen",					// auto, fullwidth, fullscreen

                fullScreenAutoWidth: "off",				// Turns the FullScreen Slider to be a FullHeight but auto Width Slider
                fullScreenAlignForce: "off",
                fullScreenOffsetContainer: "",			// Size for FullScreen Slider minimising Calculated on the Container sizes
                fullScreenOffset: "0",					// Size for FullScreen Slider minimising

                hideCaptionAtLimit: 0,					// It Defines if a caption should be shown under a Screen Resolution ( Basod on The Width of Browser)
                hideAllCaptionAtLimit: 0,				// Hide all The Captions if Width of Browser is less then this value
                hideSliderAtLimit: 0,					// Hide the whole slider, and stop also functions if Width of Browser is less than this value
                disableProgressBar: "on",				// Hides Progress Bar if is set to "on"
                stopAtSlide: false,							// Stop Timer if Slide "x" has been Reached. If stopAfterLoops set to 0, then it stops already in the first Loop at slide X which defined. -1 means do not stop at any slide. stopAfterLoops has no sinn in this case.
                stopAfterLoops: -1,						// Stop Timer if All slides has been played "x" times. IT will stop at THe slide which is defined via stopAtSlide:x, if set to -1 slide never stop automatic
                shadow: 0,								//0 = no Shadow, 1,2,3 = 3 Different Art of Shadows  (No Shadow in Fullwidth Version !)
                dottedOverlay: "none",					//twoxtwo, threexthree, twoxtwowhite, threexthreewhite
                startDelay: 0,							// Delay before the first Animation starts.
                lazyType: "smart",						//full, smart, single
                spinner: "spinner0",
                shuffle: "off",							// Random Order of Slides,

                viewPort: {
                    enable: false,						// if enabled, slider wait with start or wait at first slide.
                    outof: "wait",						// wait,pause
                    visible_area: "60%",					// Start Animation when 60% of Slider is visible
                    presize: false 						// Presize the Height of the Slider Container for Internal Link Positions
                },
                fallbacks: {
                    isJoomla: false,
                    panZoomDisableOnMobile: "off",
                    simplifyAll: "on",
                    nextSlideOnWindowFocus: "off",
                    disableFocusListener: true,
                    ignoreHeightChanges: "off",  // off, mobile, always
                    ignoreHeightChangesSize: 0
                },
                parallax: {
                    type: "off",						// off, mouse, scroll, mouse+scroll
                    levels: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85],
                    origo: "enterpoint",				// slidercenter or enterpoint
                    speed: 400,
                    bgparallax: "off",
                    opacity: "on",
                    disable_onmobile: "off",
                    ddd_shadow: "on",
                    ddd_bgfreeze: "off",
                    ddd_overflow: "visible",
                    ddd_layer_overflow: "visible",
                    ddd_z_correction: 65,
                    ddd_path: "mouse"
                },
                scrolleffect: {
                    fade: "off",
                    blur: "off",
                    grayscale: "off",
                    maxblur: 10,
                    on_layers: "off",
                    on_slidebg: "off",
                    on_static_layers: "off",
                    on_parallax_layers: "off",
                    on_parallax_static_layers: "off",
                    direction: "both",
                    multiplicator: 1.35,
                    multiplicator_layers: 0.5,
                    tilt: 30,
                    disable_on_mobile: "on"
                },
                carousel: {
                    easing: punchgs.Power3.easeInOut,
                    speed: 800,
                    showLayersAllTime: "off",
                    horizontal_align: "center",
                    vertical_align: "center",
                    infinity: "on",
                    space: 0,
                    maxVisibleItems: 3,
                    stretch: "off",
                    fadeout: "on",
                    maxRotation: 0,
                    minScale: 0,
                    vary_fade: "off",
                    vary_rotation: "on",
                    vary_scale: "off",
                    border_radius: "0px",
                    padding_top: 0,
                    padding_bottom: 0
                },
                navigation: {
                    keyboardNavigation: "off",
                    keyboard_direction: "horizontal",		//	horizontal - left/right arrows,  vertical - top/bottom arrows
                    mouseScrollNavigation: "off",			// on, off, carousel
                    onHoverStop: "off",						// Stop Banner Timet at Hover on Slide on/off

                    touch: {
                        touchenabled: "off",						// Enable Swipe Function : on/off
                        swipe_treshold: 75,					// The number of pixels that the user must move their finger by before it is considered a swipe.
                        swipe_min_touches: 1,					// Min Finger (touch) used for swipe
                        drag_block_vertical: false,				// Prevent Vertical Scroll during Swipe
                        swipe_direction: "horizontal"
                    },
                    arrows: {
                        style: "",
                        enable: false,
                        hide_onmobile: false,
                        hide_onleave: true,
                        hide_delay: 200,
                        hide_delay_mobile: 1200,
                        hide_under: 0,
                        hide_over: 9999,
                        tmp: '',
                        rtl: false,
                        left: {
                            h_align: "left",
                            v_align: "center",
                            h_offset: 20,
                            v_offset: 0,
                            container: "slider"
                        },
                        right: {
                            h_align: "right",
                            v_align: "center",
                            h_offset: 20,
                            v_offset: 0,
                            container: "slider"
                        }
                    },
                    bullets: {
                        container: "container",
                        rtl: false,
                        style: "",
                        enable: true,
                        hide_onmobile: false,
                        hide_onleave: false,
                        hide_delay: 200,
                        hide_delay_mobile: 1200,
                        hide_under: 0,
                        hide_over: 9999,
                        direction: "horizontal",
                        h_align: "center",
                        v_align: "bottom",
                        space: 5,
                        h_offset: 0,
                        v_offset: 0,
                        tmp: '<span class="tp-bullet-image"></span><span class="tp-bullet-title"></span>'
                    },
                    thumbnails: {
                        container: "slider",
                        rtl: false,
                        style: "",
                        enable: false,
                        width: 100,
                        height: 50,
                        min_width: 100,
                        wrapper_padding: 2,
                        wrapper_color: "#f5f5f5",
                        wrapper_opacity: 1,
                        tmp: '<span class="tp-thumb-image"></span><span class="tp-thumb-title"></span>',
                        visibleAmount: 5,
                        hide_onmobile: false,
                        hide_onleave: true,
                        hide_delay: 200,
                        hide_delay_mobile: 1200,
                        hide_under: 0,
                        hide_over: 9999,
                        direction: "horizontal",
                        span: false,
                        position: "inner",
                        space: 2,
                        h_align: "left",
                        v_align: "center",
                        h_offset: 20,
                        v_offset: 0
                    },
                    tabs: {
                        container: "slider",
                        rtl: false,
                        style: "",
                        enable: false,
                        width: 100,
                        min_width: 100,
                        height: 50,
                        wrapper_padding: 10,
                        wrapper_color: "#f5f5f5",
                        wrapper_opacity: 1,
                        tmp: '<span class="tp-tab-image"></span>',
                        visibleAmount: 5,
                        hide_onmobile: false,
                        hide_onleave: true,
                        hide_delay: 200,
                        hide_delay_mobile: 1200,
                        hide_under: 0,
                        hide_over: 9999,
                        direction: "horizontal",
                        span: false,
                        space: 0,
                        position: "inner",
                        h_align: "left",
                        v_align: "center",
                        h_offset: 20,
                        v_offset: 0
                    }
                },

                extensions: "extensions/",			//example extensions/ or extensions/source/
                extensions_suffix: ".min.js",
                //addons:[{fileprefix:"revolution.addon.whiteboard",init:"initWhiteBoard",params:"opt",handel:"whiteboard"}],
                debugMode: false
            });
        }
    };

    const carousel = function () {
        $('.gallery-list.has-carousel .row').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            autoplay: true,
            dots: false,
            arrows: true,
            prevArrow: '<button type="button" class="btn btn-link slick-arrow slick-prev" ><i class="fa fa-angle-left"></i></button>',
            nextArrow: '<button type="button" class="btn btn-link slick-arrow slick-next" ><i class="fa fa-angle-right"></i></button>',
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true
                    }
                }
            ]
        });
        $('.product-detail .product-gallery').slick({
            asNavFor: '.product-detail .product-thumb-gallery .list',
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            autoplay: true,
            arrows: false,
            prevArrow: '<button type="button" class="btn btn-link slick-arrow slick-prev" ><i class="fa fa-angle-left"></i></button>',
            nextArrow: '<button type="button" class="btn btn-link slick-arrow slick-next" ><i class="fa fa-angle-right"></i></button>',
            customPaging: function (slider, i) {
                return '<button type="button" class="btn btn-link"><span>' + (i + 1) + '</span></button>';
            },
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                }
            ]
        });
        $('.product-detail .product-thumb-gallery .list').slick({
            asNavFor: '.product-detail .product-gallery',
            slidesToShow: 3,
            slidesToScroll: 1,
            focusOnSelect: true,
            autoplay: true,
            dots: false,
            arrows: true,
            prevArrow: '<button type="button" class="btn btn-link slick-arrow slick-prev" ><i class="fa fa-angle-left"></i></button>',
            nextArrow: '<button type="button" class="btn btn-link slick-arrow slick-next" ><i class="fa fa-angle-right"></i></button>'
        });
    };

    const homeCarousel = function () {
        var list = $('.home-carousel');

        $.each(list, function (i, val) {
            if ($(val).attr('data-carousel-type') == 'dots') {
                var dots = true;
                var arrows = false;
                var slidesToScroll = $(val).attr('data-carousel-item');
            } else {
                var dots = false;
                var arrows = true;
                var slidesToScroll = 1;
            }

            if ($(val).attr('data-carousel-autoplay') == 1) {
                var autoplay = true;
            } else {
                var autoplay = false;
            }
            $(val).find('> .row').slick({
                slidesToShow: $(val).attr('data-carousel-item'),
                slidesToScroll: 1,
                infinite: true,
                dots: dots,
                arrows: arrows,
                autoplaySpeed: 5000,
                autoplay: autoplay,
                prevArrow: '<button type="button" class="btn btn-link slick-arrow slick-prev" ><i class="fa fa-angle-left"></i></button>',
                nextArrow: '<button type="button" class="btn btn-link slick-arrow slick-next" ><i class="fa fa-angle-right"></i></button>',
                customPaging: function (slider, i) {
                    return '<button type="button" class="btn btn-link"><span>' + (i + 1) + '</span></button>';
                },
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: $(val).attr('data-carousel-item-tablet'),
                            slidesToScroll: 1,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true
                        }
                    }
                ]
            });
        })

    };

    const dropdownMenu = function () {
        if (window_width > window_md) {
            $('header.site-head .menu nav ul li').hover(function () {
                $(this).find('ul').stop().fadeIn();
            }, function () {
                $(this).find('ul').stop().fadeOut();
            });
        }
    };

    const accordion = function () {
        var title = $('.site-content .faq-list .item .item-title');

        title.on('click', function () {
            var parent = $('.site-content .faq-list .item');
            var body = $('.site-content .faq-list .item .item-body');
            var thisParent = $(this).parents('.item');

            if (thisParent.hasClass('open')) {
                thisParent.removeClass('open border-color-1');
                thisParent.find('.item-body').slideUp();
            } else {
                parent.removeClass('open border-color-1');
                thisParent.addClass('open border-color-1');
                body.slideUp();
                thisParent.find('.item-body').slideDown();
            }
        });
    };

    this.mobileMenuOpen = function (el, panel) {
        panel.animate({
            'right': 0
        });
        $('.overlay').fadeIn();
        $('body').css({
            'overflow': 'hidden'
        });
        el.addClass('active');
    };

    this.mobileMenuClose = function (el, panel) {
        panel.animate({
            'right': '-280px'
        });
        $('.overlay').fadeOut();
        $('body').css({
            'overflow': 'auto'
        });
        el.removeClass('active');
    };

    this.stickyFooter = function () {
        if ($(window).height() > ($('body').height())) {
            $('footer.site-foot').addClass('fixed-footer');
        } else {
            $('footer.site-foot').removeClass('fixed-footer');
        }
    };

    this.maps = function (coordinate) {
        var marker, i;

        var latlng = new google.maps.LatLng(coordinate[0], coordinate[1]);
        var mapOptions = {
            scrollwheel: false,
            disableDefaultUI: true,
            zoom: 10,
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL
            },
            center: latlng,
            styles: [{
                featureType: "administrative",
                elementType: "labels.text.fill",
                stylers: [{
                    color: "#444444"
                }]
            }, {
                featureType: "landscape",
                elementType: "all",
                stylers: [{
                    color: "#f2f2f2"
                }]
            }, {
                featureType: "poi",
                elementType: "all",
                stylers: [{
                    visibility: "off"
                }]
            }, {
                featureType: "poi.business",
                elementType: "geometry.fill",
                stylers: [{
                    visibility: "on"
                }]
            }, {
                featureType: "road",
                elementType: "all",
                stylers: [{
                    saturation: -100
                }, {
                    lightness: 45
                }]
            }, {
                featureType: "road.highway",
                elementType: "all",
                stylers: [{
                    visibility: "simplified"
                }]
            }, {
                featureType: "road.arterial",
                elementType: "labels.icon",
                stylers: [{
                    visibility: "off"
                }]
            }, {
                featureType: "transit",
                elementType: "all",
                stylers: [{
                    visibility: "off"
                }]
            }, {
                featureType: "water",
                elementType: "all",
                stylers: [{
                    color: "#b4d4e1"
                }, {
                    visibility: "on"
                }]
            }]
        };

        var map = new google.maps.Map(document.getElementById('maps'), mapOptions);

        marker = new google.maps.Marker({
            position: new google.maps.LatLng(coordinate[0], coordinate[1]),
            map: map,
            icon: '/wbn-assets/images/pin.png',
            animation: google.maps.Animation.DROP
        });
    };

    this.bultenGonder = function (form) {
        $.post("/wbn-admin/ajax/ajax.php?islem=bulten-gonder", form.serialize(), function (data) {
            var data = JSON.parse(data);
            $.fancybox({
                'content': data.mesaj,
                'maxWidth': 250,
                'type': 'iframe',
                'autoSize': false,
                'autoHeight': true,
                'minHeight': '20px',
                'padding': 20
            });
        }).done(function () {

        });
    };

    this.removeLoader = function () {
        $('.loader').animate({
            top: '-100%'
        }, 500);
        $('.loader').fadeOut();
    };

    this.run = function () {
        plugins();
        javalar();
        carousel();
        slider();
        __siteJS.stickyFooter();
        dropdownMenu();
        homeCarousel();
        accordion();
    };

    this.resize = function () {

    };

    this.load = function () {

    };

};
const __siteJS = new SiteJS();


