$(document).ready(function () {
    __siteJS.run();
    __stackedLabelJS.run();
    __mobileMenuJS.run();
    __flickityCustomNavJS.run();
});

$(window).on('load', function () {
    __siteJS.stickyFooter();
    __siteJS.removeLoader();
});

$(window).on('resize', function () {
    __siteJS.stickyFooter();
});
